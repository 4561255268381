export const chartColors: any = {
    blue: 'rgb(90, 200, 250)',
    yellow: 'rgb(255, 149, 0)',
    purple: 'rgb(175, 82, 222)',
    darkBrown: 'rgb(129, 78, 40)',
    red: 'rgb(204, 53, 53)',
    lightBeige: 'rgb(234, 247, 170)',
    green: 'rgb(0, 148, 97)',
    pink: 'rgb(255, 117, 117)',
    lightBlue: 'rgb(170, 208, 255)',
    darkRed: 'rgb(114, 20, 20)',
    lightGreen: 'rgb(118, 183, 172)',
    lightPurple: 'rgb(201, 80, 195)',
    darkPurple: 'rgb(96, 28, 93)',
    beige: 'rgb(171, 181, 123)',
    darkGreen: 'rgb(33, 68, 24)',
    grey: 'rgb(150, 150, 150)',
    darkBlue: 'rgb(3, 79, 130)',
    darkGrey: 'rgb(96, 96, 96)',
    darkBeige: 'rgb(88, 94, 62)',
    lightGrey: 'rgb(216, 216, 216)',
};
