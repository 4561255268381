
    import { Vue, Component, Prop } from 'vue-property-decorator';
    import { Bar, mixins } from 'vue-chartjs';

    @Component({
        extends: Bar,
        mixins: [mixins.reactiveProp],
    })
    export default class BarChart extends Vue {

        @Prop()
        public chartData!: any;

        @Prop()
        public options!: any;

        public mounted() {
            // @ts-ignore
            this.renderChart(this.chartData, this.options);
        }
    }
