
    import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
    import { Doughnut, mixins } from 'vue-chartjs';

    @Component({
        extends: Doughnut,
        // mixins: [mixins.reactiveProp],
    })
    export default class DoughnutChart extends Vue {
        private updating: boolean = false;
        @Prop()
        private chartData!: any;

        private htmlLegend: any = null;

        @Prop()
        private options!: any;

        @Watch('chartData.datasets')
        private onDataUpdate() {
          if (!this.updating && this.$data && this.$data._chart) {
            // Update the chart
            this.updating = true;
            // @ts-ignore
            this.renderChart(this.chartData, this.options);
            // @ts-ignore
            this.$emit('generated', this.generateLegend());
            // this.$data._chart.update();
            this.$nextTick(() => this.updating = false);
          }
        }

        private mounted() {
            // @ts-ignore
            this.renderChart(this.chartData, this.options);
            // @ts-ignore
            this.$emit('generated', this.generateLegend());
        }

        private getImage() {
          // tslint:disable-next-line
          console.log(this.$data._chart.toBase64Image());
        }
    }
