
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class HorizontalBarChart extends Vue {
  @Prop({ default: [{name: '', value: '', percent: 0},
                    {name: '', value: '', percent: 0},
                    {name: '', value: '', percent: 0},
                    {name: '', value: '', percent: 0},
                    {name: '', value: '', percent: 0},
                    {name: '', value: '', percent: 0},
                    {name: '', value: '', percent: 0},
                    {name: '', value: '', percent: 0},
                    {name: '', value: '', percent: 0},
                    {name: '', value: '', percent: 0}]})
  private chartData!: any;

  @Prop({ default: '' })
  private title!: string;

}
